<template>
  <v-container fluid class="pa-0 mb-16">
    <v-row
      no-gutters
      :class="$vuetify.breakpoint.mdAndUp ? 'pl-8 pt-2' : 'mt-1 pl-4'"
    >
      <span class="font-weight-medium grey--text text--darken-4 text-h6">{{
        $t("confirmDetails")
      }}</span>
    </v-row>
    <v-row
      no-gutters
      :class="$vuetify.breakpoint.mdAndUp ? 'pl-8 pt-2' : 'mt-1 pl-4'"
    >
      <span
        class="font-weight-medium grey--text text--darken-4 text-subtitle-1"
        >{{ $t("excavationLocation") }}</span
      >
    </v-row>
    <v-row
      no-gutters
      :class="$vuetify.breakpoint.smAndDown ? 'pl-4' : 'pt-2 pl-8'"
    >
      <span
        class="font-weight-medium grey--text text--darken-4 text-subtitle-2 mr-2 my-2"
        >{{ $store.state.Ticket.ticketData ? streetAddress : "" }}</span
      >
      <a
        v-if="
          this.$store.state.Ticket.isEdit !== 'UpdateTicket' &&
          center === 'UTAH'
        "
        class="primary--text my-2 body-2"
        @click="$emit('editLocation')"
      >
        <img
          src="@/assets/images/edit_location.svg"
          role="img"
          width="13%"
          alt="edit location Logo"
        />
        <span class="text-subtitle-2 font-weight-regular"
          >Edit Location</span
        ></a
      >
    </v-row>

    <v-row
      no-gutters
      :class="$vuetify.breakpoint.mdAndUp ? 'pl-8 pt-2' : 'mt-1 pl-4'"
    >
      <v-col cols="12" md="3" lg="3" xl="3">
        <v-row no-gutters> {{ $t("county") }} : </v-row>
        <v-row
          no-gutters
          class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
        >
          {{
            $store.state.Ticket.ticketData
              ? $store.state.Ticket.ticketData.county
              : "—"
          }}
        </v-row>
      </v-col>

      <v-col cols="12" md="3" lg="3" xl="3">
        <v-row no-gutters> {{ $t("place") }} : </v-row>
        <v-row
          no-gutters
          class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
        >
          {{
            $store.state.Ticket.ticketData
              ? $store.state.Ticket.ticketData.place
              : "—"
          }}
        </v-row>
      </v-col>
    </v-row>
    <v-row
      no-gutters
      :class="$vuetify.breakpoint.mdAndUp ? 'pl-8 pt-2' : 'mt-1 pl-4'"
    >
      <v-col cols="12" md="3" lg="3" xl="3">
        <v-row no-gutters> {{ $t("cross1") }} : </v-row>
        <v-row
          no-gutters
          class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
        >
          {{
            $store.state.Ticket.ticketData.streetLocation.cross1
              ? $store.state.Ticket.ticketData.streetLocation.cross1
              : "—"
          }}
        </v-row>
      </v-col>

      <v-col
        cols="12"
        md="3"
        lg="3"
        xl="3"
        v-if="center !== 'JULIE' && center !== 'UNCC'"
      >
        <v-row no-gutters> {{ $t("cross2") }} : </v-row>
        <v-row
          no-gutters
          class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
        >
          {{
            $store.state.Ticket.ticketData.streetLocation.cross2
              ? $store.state.Ticket.ticketData.streetLocation.cross2
              : "—"
          }}
        </v-row>
      </v-col>
    </v-row>
    <v-row
      no-gutters
      :class="$vuetify.breakpoint.mdAndUp ? 'pl-8 pt-2 mb-2' : 'mt-1 pl-4 mb-2'"
      v-if="center !== 'OUPS'"
    >
      <v-col cols="12" md="7" lg="7" xl="7">
        <v-row no-gutters> {{ $t("location") }} : </v-row>
        <v-row
          no-gutters
          class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
        >
          {{
            $store.state.Ticket.ticketData.streetLocation.description
              ? $store.state.Ticket.ticketData.streetLocation.description
              : "—"
          }}
        </v-row>
      </v-col>
    </v-row>

    <v-row
      no-gutters
      :class="$vuetify.breakpoint.smAndDown ? '' : 'pt-2 pl-8'"
      class="pt-2"
    >
      <img
        id="fullTicketMap"
        :src="mapView"
        style="border: 1px solid grey"
        :style="{
          width: $vuetify.breakpoint.mdAndUp
            ? '705px'
            : $vuetify.breakpoint.width + 'px',
        }"
        alt="Map"
      />
    </v-row>
    <v-row
      v-if="$vuetify.breakpoint.mdAndUp"
      :class="$vuetify.breakpoint.mdAndUp ? 'pl-8 pt-2' : 'mt-1 mx-5'"
      class="mb-2"
    >
      <v-col> <v-divider></v-divider></v-col
      ><v-col v-if="$vuetify.breakpoint.mdAndUp" cols="5"> </v-col>
    </v-row>
    <v-row
      no-gutters
      :class="$vuetify.breakpoint.mdAndUp ? 'pl-8 pt-2' : 'mt-1 mx-5'"
    >
      <v-col>
        <v-row no-gutters class="mt-2">
          <span
            class="font-weight-medium grey--text text--darken-4 text-subtitle-1"
            >{{ $t("811Center") }}</span
          >
        </v-row>
        <v-row
          no-gutters
          :class="$vuetify.breakpoint.mdAndUp ? 'pt-5 pb-2' : 'py-3'"
        >
          <v-col lg="4" md="4" xl="4">
            <img
              v-if="center === 'JULIE'"
              class="pa-4"
              style="border: 1px solid #e0e0e0; border-radius: 8%"
              src="@/assets/images/julie-811-center.svg"
              role="img"
              alt="Julie 811 Center Logo"
            />
            <img
              v-if="center === 'OUPS'"
              class="pa-4"
              style="border: 1px solid #e0e0e0; border-radius: 8%"
              src="@/assets/images/ohio-811.svg"
              role="img"
              alt="Ohio 811 Center Logo"
            />
            <img
              v-if="center === 'UTAH'"
              class="pa-4"
              style="border: 1px solid #e0e0e0; border-radius: 8%"
              src="@/assets/images/utah-811.svg"
              role="img"
              alt="UTAH 811 Center Logo"
            />
            <img
              v-if="center === 'NCOCC'"
              class="py-4"
              src="@/assets/images/NC811.svg"
              role="img"
              alt="NC 811 Center Logo"
            />
            <img
              v-if="center === 'UNCC'"
              class="pa-4"
              style="border: 1px solid #e0e0e0; border-radius: 8%"
              src="@/assets/images/CO811.svg"
              role="img"
              alt="Colorado 811 Center Logo"
            />
            <img
              v-if="center === 'VUPS'"
              class="pa-4"
              style="border: 1px solid #e0e0e0; border-radius: 8%"
              src="@/assets/images/VA811.svg"
              role="img"
              alt="VA 811 Center Logo"
            />
            <img
              v-if="center === 'USAS'"
              class="pa-4"
              style="border: 1px solid #e0e0e0; border-radius: 8%"
              src="@/assets/images/USAS-digalert-logo.svg"
              role="img"
              alt="USAS Digalert Logo"
            />
          </v-col>
          <v-col :class="$vuetify.breakpoint.mdAndUp ? 'mt-1' : 'ml-10'">
            <v-row no-gutters>
              <span
                class="font-weight-normal grey--text text--darken-1 text-caption"
                >{{ $t("excavatorType") }}</span
              >
            </v-row>
            <v-row no-gutters>
              <span
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
                >{{ companyExcavatorType }}</span
              >
            </v-row>
          </v-col>
        </v-row>

        <v-row v-if="$vuetify.breakpoint.mdAndUp" class="mb-2">
          <v-divider></v-divider>
        </v-row>

        <v-row no-gutters>
          <v-col class="mt-4 mb-10">
            <div v-if="checkPermission('associateTicketExcavationDetails')">
              <v-row class="mb-2" no-gutters>
                <span
                  class="font-weight-medium grey--text text--darken-4 text-subtitle-1 mr-1"
                  >{{ $t("associatedProject") }}</span
                >
                <a class="primary--text my-1 body-2" @click="onBackButton">
                  <v-icon color="primary" small>mdi-pencil-outline</v-icon>
                  <span class="text-subtitle-2 font-weight-regular"
                    >Edit Details</span
                  ></a
                >
              </v-row>
              <v-row class="my-4 ml-1">
                <v-col>
                  <v-row class="caption grey--text text--darken-1">{{
                    $t("projectName")
                  }}</v-row>
                  <v-row
                    class="grey--text text--darken-4 font-weight-medium body-2"
                  >
                    {{
                      excavationDetails.associateProject &&
                      excavationDetails.associateProject.project_name
                        ? excavationDetails.associateProject.project_name
                        : ""
                    }}
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-if="$vuetify.breakpoint.mdAndUp" class="mb-2">
                <v-divider></v-divider>
              </v-row>
            </div>
            <v-row class="mb-2" no-gutters>
              <span
                class="font-weight-medium grey--text text--darken-4 text-subtitle-1 mr-1"
                >{{ $t("excavationDetails") }}</span
              >
              <a class="primary--text my-1 body-2" @click="onBackButton">
                <v-icon color="primary" small>mdi-pencil-outline</v-icon>
                <span class="text-subtitle-2 font-weight-regular"
                  >Edit Details</span
                ></a
              >
            </v-row>
            <TicketContactDetails
              v-if="center !== 'USAS'"
              :excavation-details="excavationDetails"
            />
            <OhioReviewTicket
              v-if="center === 'OUPS'"
              :excavation-details="excavationDetails"
            />
            <JulieRevieTicket
              v-if="center === 'JULIE'"
              :excavation-details="excavationDetails"
            />
            <UtahReviewTicket
              v-if="center === 'UTAH'"
              :excavation-details="excavationDetails"
            />
            <NcReviewTicktet
              v-if="center === 'NCOCC'"
              :excavation-details="excavationDetails"
            />
            <UnccReviewTicktet
              v-if="center === 'UNCC'"
              :excavation-details="excavationDetails"
            />
            <VupsReviewTicket
              v-if="center === 'VUPS'"
              :excavation-details="excavationDetails"
            />
            <UsasReviewTicket
              v-if="center === 'USAS'"
              :excavation-details="excavationDetails"
            />
            <v-row v-if="$vuetify.breakpoint.mdAndUp" class="ml-1 mt-16">
              <v-btn
                color="primary"
                class="text-none rounded-lg elevation-0"
                outlined
                width="90"
                @click="onBackButton"
              >
                {{ $t("back") }}
              </v-btn>
              <v-btn
                v-if="checkPermission('scheduleTickets')"
                color="primary"
                class="ml-6 text-none rounded-lg elevation-0"
                outlined
                :disabled="approver"
                @click="scheduleTicketDialog"
              >
                {{ $t("scheduleForLater") }}
              </v-btn>
              <v-btn
                class="ml-6 primary text-none rounded-lg elevation-0"
                width="90"
                depressed
                :disabled="isEditTicket === 'ScheduleTicket' || isSubmitClicked"
                @click="submitTicket"
              >
                {{ $t("submit") }}
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="5"> </v-col>
    </v-row>
    <v-bottom-navigation
      v-if="$vuetify.breakpoint.smAndDown"
      fixed
      horizontal
      height="70"
      class="px-2 py-4"
      ><v-row no-gutters>
        <v-btn
          outlined
          color="primary"
          class="rounded-lg"
          height="50"
          @click="onBackButton"
        >
          <v-icon color="primary">mdi-arrow-left</v-icon>
        </v-btn>
        <v-btn
          color="primary"
          class="text-none rounded-lg text--primary elevation-0"
          :class="$vuetify.breakpoint.width < 350 ? 'mx-1' : 'mx-6'"
          outlined
          :disabled="approver"
          @click="scheduleTicketDialog"
        >
          {{ $t("schedule") }}
        </v-btn>
        <v-btn
          color="primary"
          class="text-none rounded-lg font-weight-regular text-h6 white--text"
          height="50"
          :disabled="isEditTicket === 'ScheduleTicket' || isSubmitClicked"
          :width="$vuetify.breakpoint.width < 355 ? 58 : 118"
          @click="submitTicket"
          ><span class="white--text">{{ $t("submit") }}</span>
        </v-btn>
      </v-row></v-bottom-navigation
    >
    <ScheduleTicketDialog
      :dialog="scheduleDialog"
      @scheduleTicket="scheduleTicket"
      @closeDialog="closeDialog"
    />
  </v-container>
</template>
<script>
import moment from "moment";
import UserDataMixin from "@/mixins/UserData";
export default {
  components: {
    TicketContactDetails: () => import("./TicketContactDetails.vue"),
    OhioReviewTicket: () => import("./centers/ohio/OhioReviewTicket.vue"),
    JulieRevieTicket: () => import("./centers/julie/JulieRevieTicket.vue"),
    UtahReviewTicket: () => import("./centers/utah/UtahReviewTicket.vue"),
    ScheduleTicketDialog: () => import("./ScheduleTicketDialog.vue"),
    NcReviewTicktet: () => import("./centers/ncocc/NcReviewTicktet.vue"),
    UnccReviewTicktet: () => import("./centers/uncc/UnccReviewTicktet.vue"),
    VupsReviewTicket: () => import("./centers/vups/VupsReviewTicket"),
    UsasReviewTicket: () => import("./centers/usas/UsasReviewTicket.vue"),
  },
  mixins: [UserDataMixin],
  props: {
    mapView: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      center: this.$store.state.Ticket.ticketData.center,
      scheduleDialog: false,
      isSubmitClicked: false,
    };
  },
  computed: {
    isEditTicket() {
      return this.$store.state.Ticket.isEdit;
    },
    approver() {
      return this.$store.state.User.user.approver_id ? true : false;
    },
    companyExcavatorType() {
      let excavatorType = "";
      if (
        this.$store.state.Company.company.centers &&
        this.$store.state.Company.company.centers.length > 0
      ) {
        excavatorType = this.$store.state.Company.company.centers.filter(
          (item) => {
            return item.abbrev === this.center;
          }
        )[0].excavator_type;
      }
      return excavatorType;
    },
    excavationDetails() {
      return this.$store.state.Ticket.excavationDetails;
    },
    streetAddress() {
      const streetFromAddress =
        this.$store.state.Ticket.ticketData?.st_from_address;
      const street = this.$store.state.Ticket.ticketData?.street;
      return (streetFromAddress ? streetFromAddress + " " : "") + street;
    },
  },
  created() {
    this.moment = moment;
  },
  methods: {
    onBackButton() {
      this.$emit("reviewTicketBack");
    },
    submitTicket() {
      this.isSubmitClicked = true;
      this.$emit("submitTicket");
    },
    scheduleTicketDialog() {
      this.scheduleDialog = true;
    },
    scheduleTicket(scheduleObject) {
      this.$emit("scheduleTicket", scheduleObject);
    },
    closeDialog() {
      this.scheduleDialog = false;
    },
  },
};
</script>
