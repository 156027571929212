var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0 mb-16", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        {
          class: _vm.$vuetify.breakpoint.mdAndUp ? "pl-8 pt-2" : "mt-1 pl-4",
          attrs: { "no-gutters": "" },
        },
        [
          _c(
            "span",
            {
              staticClass:
                "font-weight-medium grey--text text--darken-4 text-h6",
            },
            [_vm._v(_vm._s(_vm.$t("confirmDetails")))]
          ),
        ]
      ),
      _c(
        "v-row",
        {
          class: _vm.$vuetify.breakpoint.mdAndUp ? "pl-8 pt-2" : "mt-1 pl-4",
          attrs: { "no-gutters": "" },
        },
        [
          _c(
            "span",
            {
              staticClass:
                "font-weight-medium grey--text text--darken-4 text-subtitle-1",
            },
            [_vm._v(_vm._s(_vm.$t("excavationLocation")))]
          ),
        ]
      ),
      _c(
        "v-row",
        {
          class: _vm.$vuetify.breakpoint.smAndDown ? "pl-4" : "pt-2 pl-8",
          attrs: { "no-gutters": "" },
        },
        [
          _c(
            "span",
            {
              staticClass:
                "font-weight-medium grey--text text--darken-4 text-subtitle-2 mr-2 my-2",
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$store.state.Ticket.ticketData ? _vm.streetAddress : ""
                )
              ),
            ]
          ),
          this.$store.state.Ticket.isEdit !== "UpdateTicket" &&
          _vm.center === "UTAH"
            ? _c(
                "a",
                {
                  staticClass: "primary--text my-2 body-2",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("editLocation")
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/edit_location.svg"),
                      role: "img",
                      width: "13%",
                      alt: "edit location Logo",
                    },
                  }),
                  _c(
                    "span",
                    { staticClass: "text-subtitle-2 font-weight-regular" },
                    [_vm._v("Edit Location")]
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
      _c(
        "v-row",
        {
          class: _vm.$vuetify.breakpoint.mdAndUp ? "pl-8 pt-2" : "mt-1 pl-4",
          attrs: { "no-gutters": "" },
        },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3", lg: "3", xl: "3" } },
            [
              _c("v-row", { attrs: { "no-gutters": "" } }, [
                _vm._v(" " + _vm._s(_vm.$t("county")) + " : "),
              ]),
              _c(
                "v-row",
                {
                  staticClass:
                    "font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1",
                  attrs: { "no-gutters": "" },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$store.state.Ticket.ticketData
                          ? _vm.$store.state.Ticket.ticketData.county
                          : "—"
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3", lg: "3", xl: "3" } },
            [
              _c("v-row", { attrs: { "no-gutters": "" } }, [
                _vm._v(" " + _vm._s(_vm.$t("place")) + " : "),
              ]),
              _c(
                "v-row",
                {
                  staticClass:
                    "font-weight-medium grey--text text--darken-4 text-subtitle-2",
                  attrs: { "no-gutters": "" },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$store.state.Ticket.ticketData
                          ? _vm.$store.state.Ticket.ticketData.place
                          : "—"
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        {
          class: _vm.$vuetify.breakpoint.mdAndUp ? "pl-8 pt-2" : "mt-1 pl-4",
          attrs: { "no-gutters": "" },
        },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3", lg: "3", xl: "3" } },
            [
              _c("v-row", { attrs: { "no-gutters": "" } }, [
                _vm._v(" " + _vm._s(_vm.$t("cross1")) + " : "),
              ]),
              _c(
                "v-row",
                {
                  staticClass:
                    "font-weight-medium grey--text text--darken-4 text-subtitle-2",
                  attrs: { "no-gutters": "" },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$store.state.Ticket.ticketData.streetLocation.cross1
                          ? _vm.$store.state.Ticket.ticketData.streetLocation
                              .cross1
                          : "—"
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm.center !== "JULIE" && _vm.center !== "UNCC"
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "3", lg: "3", xl: "3" } },
                [
                  _c("v-row", { attrs: { "no-gutters": "" } }, [
                    _vm._v(" " + _vm._s(_vm.$t("cross2")) + " : "),
                  ]),
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "font-weight-medium grey--text text--darken-4 text-subtitle-2",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$store.state.Ticket.ticketData.streetLocation
                              .cross2
                              ? _vm.$store.state.Ticket.ticketData
                                  .streetLocation.cross2
                              : "—"
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.center !== "OUPS"
        ? _c(
            "v-row",
            {
              class: _vm.$vuetify.breakpoint.mdAndUp
                ? "pl-8 pt-2 mb-2"
                : "mt-1 pl-4 mb-2",
              attrs: { "no-gutters": "" },
            },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "7", lg: "7", xl: "7" } },
                [
                  _c("v-row", { attrs: { "no-gutters": "" } }, [
                    _vm._v(" " + _vm._s(_vm.$t("location")) + " : "),
                  ]),
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "font-weight-medium grey--text text--darken-4 text-subtitle-2",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$store.state.Ticket.ticketData.streetLocation
                              .description
                              ? _vm.$store.state.Ticket.ticketData
                                  .streetLocation.description
                              : "—"
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        {
          staticClass: "pt-2",
          class: _vm.$vuetify.breakpoint.smAndDown ? "" : "pt-2 pl-8",
          attrs: { "no-gutters": "" },
        },
        [
          _c("img", {
            staticStyle: { border: "1px solid grey" },
            style: {
              width: _vm.$vuetify.breakpoint.mdAndUp
                ? "705px"
                : _vm.$vuetify.breakpoint.width + "px",
            },
            attrs: { id: "fullTicketMap", src: _vm.mapView, alt: "Map" },
          }),
        ]
      ),
      _vm.$vuetify.breakpoint.mdAndUp
        ? _c(
            "v-row",
            {
              staticClass: "mb-2",
              class: _vm.$vuetify.breakpoint.mdAndUp
                ? "pl-8 pt-2"
                : "mt-1 mx-5",
            },
            [
              _c("v-col", [_c("v-divider")], 1),
              _vm.$vuetify.breakpoint.mdAndUp
                ? _c("v-col", { attrs: { cols: "5" } })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        {
          class: _vm.$vuetify.breakpoint.mdAndUp ? "pl-8 pt-2" : "mt-1 mx-5",
          attrs: { "no-gutters": "" },
        },
        [
          _c(
            "v-col",
            [
              _c(
                "v-row",
                { staticClass: "mt-2", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "font-weight-medium grey--text text--darken-4 text-subtitle-1",
                    },
                    [_vm._v(_vm._s(_vm.$t("811Center")))]
                  ),
                ]
              ),
              _c(
                "v-row",
                {
                  class: _vm.$vuetify.breakpoint.mdAndUp ? "pt-5 pb-2" : "py-3",
                  attrs: { "no-gutters": "" },
                },
                [
                  _c("v-col", { attrs: { lg: "4", md: "4", xl: "4" } }, [
                    _vm.center === "JULIE"
                      ? _c("img", {
                          staticClass: "pa-4",
                          staticStyle: {
                            border: "1px solid #e0e0e0",
                            "border-radius": "8%",
                          },
                          attrs: {
                            src: require("@/assets/images/julie-811-center.svg"),
                            role: "img",
                            alt: "Julie 811 Center Logo",
                          },
                        })
                      : _vm._e(),
                    _vm.center === "OUPS"
                      ? _c("img", {
                          staticClass: "pa-4",
                          staticStyle: {
                            border: "1px solid #e0e0e0",
                            "border-radius": "8%",
                          },
                          attrs: {
                            src: require("@/assets/images/ohio-811.svg"),
                            role: "img",
                            alt: "Ohio 811 Center Logo",
                          },
                        })
                      : _vm._e(),
                    _vm.center === "UTAH"
                      ? _c("img", {
                          staticClass: "pa-4",
                          staticStyle: {
                            border: "1px solid #e0e0e0",
                            "border-radius": "8%",
                          },
                          attrs: {
                            src: require("@/assets/images/utah-811.svg"),
                            role: "img",
                            alt: "UTAH 811 Center Logo",
                          },
                        })
                      : _vm._e(),
                    _vm.center === "NCOCC"
                      ? _c("img", {
                          staticClass: "py-4",
                          attrs: {
                            src: require("@/assets/images/NC811.svg"),
                            role: "img",
                            alt: "NC 811 Center Logo",
                          },
                        })
                      : _vm._e(),
                    _vm.center === "UNCC"
                      ? _c("img", {
                          staticClass: "pa-4",
                          staticStyle: {
                            border: "1px solid #e0e0e0",
                            "border-radius": "8%",
                          },
                          attrs: {
                            src: require("@/assets/images/CO811.svg"),
                            role: "img",
                            alt: "Colorado 811 Center Logo",
                          },
                        })
                      : _vm._e(),
                    _vm.center === "VUPS"
                      ? _c("img", {
                          staticClass: "pa-4",
                          staticStyle: {
                            border: "1px solid #e0e0e0",
                            "border-radius": "8%",
                          },
                          attrs: {
                            src: require("@/assets/images/VA811.svg"),
                            role: "img",
                            alt: "VA 811 Center Logo",
                          },
                        })
                      : _vm._e(),
                    _vm.center === "USAS"
                      ? _c("img", {
                          staticClass: "pa-4",
                          staticStyle: {
                            border: "1px solid #e0e0e0",
                            "border-radius": "8%",
                          },
                          attrs: {
                            src: require("@/assets/images/USAS-digalert-logo.svg"),
                            role: "img",
                            alt: "USAS Digalert Logo",
                          },
                        })
                      : _vm._e(),
                  ]),
                  _c(
                    "v-col",
                    {
                      class: _vm.$vuetify.breakpoint.mdAndUp ? "mt-1" : "ml-10",
                    },
                    [
                      _c("v-row", { attrs: { "no-gutters": "" } }, [
                        _c(
                          "span",
                          {
                            staticClass:
                              "font-weight-normal grey--text text--darken-1 text-caption",
                          },
                          [_vm._v(_vm._s(_vm.$t("excavatorType")))]
                        ),
                      ]),
                      _c("v-row", { attrs: { "no-gutters": "" } }, [
                        _c(
                          "span",
                          {
                            staticClass:
                              "font-weight-medium grey--text text--darken-4 text-subtitle-2",
                          },
                          [_vm._v(_vm._s(_vm.companyExcavatorType))]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.$vuetify.breakpoint.mdAndUp
                ? _c("v-row", { staticClass: "mb-2" }, [_c("v-divider")], 1)
                : _vm._e(),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "mt-4 mb-10" },
                    [
                      _vm.checkPermission("associateTicketExcavationDetails")
                        ? _c(
                            "div",
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "mb-2",
                                  attrs: { "no-gutters": "" },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "font-weight-medium grey--text text--darken-4 text-subtitle-1 mr-1",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("associatedProject"))
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "primary--text my-1 body-2",
                                      on: { click: _vm.onBackButton },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            color: "primary",
                                            small: "",
                                          },
                                        },
                                        [_vm._v("mdi-pencil-outline")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-subtitle-2 font-weight-regular",
                                        },
                                        [_vm._v("Edit Details")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "v-row",
                                { staticClass: "my-4 ml-1" },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass:
                                            "caption grey--text text--darken-1",
                                        },
                                        [_vm._v(_vm._s(_vm.$t("projectName")))]
                                      ),
                                      _c(
                                        "v-row",
                                        {
                                          staticClass:
                                            "grey--text text--darken-4 font-weight-medium body-2",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.excavationDetails
                                                  .associateProject &&
                                                  _vm.excavationDetails
                                                    .associateProject
                                                    .project_name
                                                  ? _vm.excavationDetails
                                                      .associateProject
                                                      .project_name
                                                  : ""
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.$vuetify.breakpoint.mdAndUp
                                ? _c(
                                    "v-row",
                                    { staticClass: "mb-2" },
                                    [_c("v-divider")],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-row",
                        { staticClass: "mb-2", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-medium grey--text text--darken-4 text-subtitle-1 mr-1",
                            },
                            [_vm._v(_vm._s(_vm.$t("excavationDetails")))]
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "primary--text my-1 body-2",
                              on: { click: _vm.onBackButton },
                            },
                            [
                              _c(
                                "v-icon",
                                { attrs: { color: "primary", small: "" } },
                                [_vm._v("mdi-pencil-outline")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-subtitle-2 font-weight-regular",
                                },
                                [_vm._v("Edit Details")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm.center !== "USAS"
                        ? _c("TicketContactDetails", {
                            attrs: {
                              "excavation-details": _vm.excavationDetails,
                            },
                          })
                        : _vm._e(),
                      _vm.center === "OUPS"
                        ? _c("OhioReviewTicket", {
                            attrs: {
                              "excavation-details": _vm.excavationDetails,
                            },
                          })
                        : _vm._e(),
                      _vm.center === "JULIE"
                        ? _c("JulieRevieTicket", {
                            attrs: {
                              "excavation-details": _vm.excavationDetails,
                            },
                          })
                        : _vm._e(),
                      _vm.center === "UTAH"
                        ? _c("UtahReviewTicket", {
                            attrs: {
                              "excavation-details": _vm.excavationDetails,
                            },
                          })
                        : _vm._e(),
                      _vm.center === "NCOCC"
                        ? _c("NcReviewTicktet", {
                            attrs: {
                              "excavation-details": _vm.excavationDetails,
                            },
                          })
                        : _vm._e(),
                      _vm.center === "UNCC"
                        ? _c("UnccReviewTicktet", {
                            attrs: {
                              "excavation-details": _vm.excavationDetails,
                            },
                          })
                        : _vm._e(),
                      _vm.center === "VUPS"
                        ? _c("VupsReviewTicket", {
                            attrs: {
                              "excavation-details": _vm.excavationDetails,
                            },
                          })
                        : _vm._e(),
                      _vm.center === "USAS"
                        ? _c("UsasReviewTicket", {
                            attrs: {
                              "excavation-details": _vm.excavationDetails,
                            },
                          })
                        : _vm._e(),
                      _vm.$vuetify.breakpoint.mdAndUp
                        ? _c(
                            "v-row",
                            { staticClass: "ml-1 mt-16" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "text-none rounded-lg elevation-0",
                                  attrs: {
                                    color: "primary",
                                    outlined: "",
                                    width: "90",
                                  },
                                  on: { click: _vm.onBackButton },
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("back")) + " ")]
                              ),
                              _vm.checkPermission("scheduleTickets")
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "ml-6 text-none rounded-lg elevation-0",
                                      attrs: {
                                        color: "primary",
                                        outlined: "",
                                        disabled: _vm.approver,
                                      },
                                      on: { click: _vm.scheduleTicketDialog },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("scheduleForLater")) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "ml-6 primary text-none rounded-lg elevation-0",
                                  attrs: {
                                    width: "90",
                                    depressed: "",
                                    disabled:
                                      _vm.isEditTicket === "ScheduleTicket" ||
                                      _vm.isSubmitClicked,
                                  },
                                  on: { click: _vm.submitTicket },
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("submit")) + " ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.$vuetify.breakpoint.mdAndUp
            ? _c("v-col", { attrs: { cols: "5" } })
            : _vm._e(),
        ],
        1
      ),
      _vm.$vuetify.breakpoint.smAndDown
        ? _c(
            "v-bottom-navigation",
            {
              staticClass: "px-2 py-4",
              attrs: { fixed: "", horizontal: "", height: "70" },
            },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "rounded-lg",
                      attrs: { outlined: "", color: "primary", height: "50" },
                      on: { click: _vm.onBackButton },
                    },
                    [
                      _c("v-icon", { attrs: { color: "primary" } }, [
                        _vm._v("mdi-arrow-left"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "text-none rounded-lg text--primary elevation-0",
                      class:
                        _vm.$vuetify.breakpoint.width < 350 ? "mx-1" : "mx-6",
                      attrs: {
                        color: "primary",
                        outlined: "",
                        disabled: _vm.approver,
                      },
                      on: { click: _vm.scheduleTicketDialog },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("schedule")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "text-none rounded-lg font-weight-regular text-h6 white--text",
                      attrs: {
                        color: "primary",
                        height: "50",
                        disabled:
                          _vm.isEditTicket === "ScheduleTicket" ||
                          _vm.isSubmitClicked,
                        width: _vm.$vuetify.breakpoint.width < 355 ? 58 : 118,
                      },
                      on: { click: _vm.submitTicket },
                    },
                    [
                      _c("span", { staticClass: "white--text" }, [
                        _vm._v(_vm._s(_vm.$t("submit"))),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("ScheduleTicketDialog", {
        attrs: { dialog: _vm.scheduleDialog },
        on: {
          scheduleTicket: _vm.scheduleTicket,
          closeDialog: _vm.closeDialog,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }